<template>
    <section class="ecommerce-application">
        <b-row>
            <b-col cols="12" md="2" class="mb-3">
                <b-input name="query" v-model="query" placeholder="Search Template" />
            </b-col>
            <b-col cols="12" class="d-none d-md-block">
                <b-tabs
                    pills
                    vertical
                    v-model="tabIndex"
                >
                    <b-tab :title="group.label" v-for="(group, gk) in templateGroups" :key="`gk_${gk}`">
                        <GrouppedTemplates :templates="templates" />
                    </b-tab>
                </b-tabs>
            </b-col>
            <b-col cols="12" class="d-block d-md-none">
                <GrouppedTemplates :templates="templates" />
            </b-col>
        </b-row>


        

        <b-row class="mt-3">
            <b-col cols="12" class="mt-3">
                <good-data-table ref="savedGeneratedTable" :columns="fields" :no-serial="true"
                        :total-column="'response.responseData.total'" :data-column="'response.responseData.data'"
                        :api-endpoint="'/ai/saved-generated-templates'"
                        @add-new-info="toggelModal = true" :hideExportBtns="true" />
            </b-col>
        </b-row>

        <b-textarea id="copyInput" :value="copyInputValue" style="height: 1px;width:1px;border: none; background: transparent;color: transparent;overflow: hidden;resize: none;padding: 0 0 0 0;" readonly />

    </section>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showLoader, showSuccessNotification, hideLoader } from '@/@core/comp-functions/ui/app'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import GrouppedTemplates from '@/views/users/ai/components/GrouppedTemplates.vue'
export default {
    name: 'AiTemplates',
    components: {
        GoodDataTable,
        GrouppedTemplates
    },
    props: {

    },

    data: () => ({
        loading: false,
        query: null,
        group: null,
        copyInputValue: null,
        tabIndex: 0
    }),

    computed: {
        ...mapGetters('aiTemplate', [
            'aiTemplatesLoaded',
            'aiTemplateGroups',
            'aiTemplateBySlug',
            'aiTemplates',
            'aiTemplatesByGroupId',
        ]),
        templateGroups: {
            get(){
                const templateGroups =  []
                templateGroups.push({
                    label: "All Templates",
                    id: null
                })
                return templateGroups.concat(this.aiTemplateGroups)
            }
        },
        templates: {
            get(){
                let templates = []
                if(isEmpty(this.group)){
                    templates = this.aiTemplates
                }else{
                    templates = this.aiTemplatesByGroupId(this.group)
                }

                if(isEmpty(this.query)) return templates
                const queries = this.query.toLowerCase().split(" ").filter(i => !isEmpty(i))

                if(isEmpty(queries)) return templates

                templates = templates.filter(i => {
                    let { label } = i

                    const labels = label.toLowerCase().split(" ").filter(j => !isEmpty(j))
                    if(isEmpty(labels)) return false;

                    const firstFilter = labels.some(j => queries.some(k => k == j))
                    if(firstFilter) return true;
                    
                    const secondFilter = queries.some(j => labels.some(k => k.includes(j)))
                    if(secondFilter) return true;
                    
                    return false;
                })
                
                return templates;
            }
        },
        fields: {
            get() {
                return [
                    {
                        field: 'title',
                        label: 'Title',
                        align: 'center',
                        useHtml: true,
                        renderer: props => {
                            return props.ai_template.label;
                        },
                    },
                    {
                        field: 'data',
                        label: 'Data',
                        align: 'center',
                        useHtml: true,
                        renderer: props => {
                            const fields = eval(props.ai_template.fields)
                            const postData = JSON.parse(props.post_data)
                            let div = '';
                            fields.forEach(field => {
                                const {name, label} = field
                                const value = postData[name]
                                div = div+`<div>${label}: ${value}</div>`;
                            })
                            return div
                        },
                    },
                    {
                        label: 'Action',
                        field: 'action',
                        align: 'center',
                        sortable: false,
                        type: 'dropdown',
                        actions: [
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('download'),
                                text: 'Download',
                                action: props => {
                                    this._downloadTemplate(props)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('copy'),
                                text: 'Copy',
                                action: props => {
                                    this._copyTemplate(props)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('edit'),
                                text: 'Edit',
                                action: props => {
                                    this._editTemplate(props)
                                },
                            },
                            {
                                isIcon: true,
                                iconHtml: this.$helpers.getIcons('delete'),
                                text: 'Delete',
                                action: props => {
                                    this.$swal({
                                        title: this.$t('Do you want to delete this file?'),
                                        iconHtml: this.$helpers.swalIcon('alert.png'),
                                        showCancelButton: true,
                                        confirmButtonText: this.$t('Yes'),
                                        cancelButtonText: this.$t('No'),
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger ml-1',
                                            icon: 'border-0',
                                        },
                                        buttonsStyling: false,
                                    }).then(result => {
                                        if (result.value) {
                                            this._deleteTemplate(props)
                                        }
                                    })
                                },
                            },
                        ],
                    },
                ]
            }
        }
    },
    mounted() {
        if(!this.aiTemplatesLoaded){
            showLoader()
            this.getAiTemplateGroups().then(r => {
                hideLoader()
            }).catch(e => {
                hideLoader()
            })
        }
    },
    methods: {
        isEmpty,
        ...mapActions('aiTemplate', [
            'getAiTemplateGroups',
            'generateTemplate',
            'deleteGeneratedTemplate',
        ]),
        _generateTemplate(){
            this.loading = true
            showLoader()
            const payLoad = {
                query: this.query,
                audio: this.audio,
            }
            this.generateTemplate(payLoad).then(r => {
                if (r._statusCode === 200) {
                    this.response = r.responseData
                }else if (r._statusCode === 201) {
                    showErrorNotification(this, r.message)
                }else {
                    showErrorNotification(this, "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                showErrorNotification(this, "Something went wrong!")
                this.loading = false
                hideLoader()
            });
        },
        async _editTemplate(template){
            this.$router.push({
                name: 'edit-ai-generated-template',
                params: {
                    id: template.id
                }
            })
        },
        async _deleteTemplate(template){
            showLoader()
            this.deleteGeneratedTemplate(template.id).then(r => {
                if(r === true){
                    showSuccessNotification(this, "Template Deleted")
                }
                hideLoader()
            }).catch(e => {
                hideLoader()
            })
        },
        async _downloadTemplate(template){
            if(isEmpty(template.pdf_url)) return false;
            window.location.href = jwtDefaultConfig.baseEndpoint+template.pdf_url;
        },
        async _copyTemplate(template){
            this.copyInputValue = !isEmpty(template.saved_template) ? template.saved_template : template.template
            setTimeout(() => {
                let copyInput = document.querySelector('#copyInput');
                copyInput.select();
                try {
                    if(document.execCommand('copy')){
                        showSuccessNotification(this, 'Copied to clipboard');
                    }else{
                        showErrorNotification(this, "Failed to copy");
                    }
                } catch (err) {
                    showErrorNotification(this, "Failed to copy");
                }
                window.getSelection().removeAllRanges()
            }, 100);
        },
    },

    watch: {
        query: {
            handler(nv){
                if(!isEmpty(nv)){
                    this.group = null
                }
            }
        },
        tabIndex: {
            handler(nv){
                const {id, label} = this.templateGroups[nv]
                if(!isEmpty(label)){
                    this.group = id
                }
            },
            deep: true,
            immediate: true
        }
    }

}
</script>
