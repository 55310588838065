<template>
    <b-row>
        <b-col cols="12" md="3" v-for="(template, tk) in templates" :key="`template_${tk}`">
          <router-link :to="{ name: 'user-ai-generate-template', params: { slug: template.slug } }">
            <b-card class="card-designs h-20vh">
                <b-card-header class="py-0 pl-1">
                  <b-card-title class="text-uppercase">
                    {{ template.label }}
                  </b-card-title>
                </b-card-header>
                <b-card-body class="py-0 pl-1 mt-1 text-muted">
                  <h4 style="font-size: 1rem">{{ template.short_desc }}</h4>
                </b-card-body>
            </b-card>
          </router-link>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "GrouppedTemplates",
    props: ['templates'],
}
</script>